import { FC } from 'react';
import IconProps from './IconProps';

const SpeechQuestionIcon: FC<IconProps> = (props) => {
  const { onClick, className, gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    <svg className={`inline ${cursor} ${className}`} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5127 14.5126C12.5148 14.8312 12.3161 15.1201 11.9974 15.1201H11.6226C11.3897 15.1201 11.1781 14.9687 11.0964 14.7506C11.0151 14.5383 10.9757 14.3538 10.9757 14.1931C10.9757 13.8898 11.0176 13.5987 11.1015 13.3182C11.1853 13.0366 11.3086 12.7758 11.4732 12.5317C11.6359 12.2901 11.9586 11.9004 12.4395 11.3626C12.9224 10.8278 13.1643 10.386 13.1643 10.0388C13.1643 9.36352 12.7254 9.02537 11.846 9.02537C11.6655 9.02537 11.4821 9.06287 11.2955 9.13791C10.8471 9.31816 10.2846 9.24719 10.0571 8.82082C9.88739 8.50262 9.96821 8.09959 10.2916 7.93993C10.8243 7.67692 11.4623 7.54541 12.2076 7.54541C12.984 7.54541 13.6341 7.76225 14.1569 8.19593C14.6761 8.63011 14.9378 9.20683 14.9378 9.92457C14.9378 10.4174 14.8438 10.8344 14.6539 11.172C14.4645 11.5117 14.1321 11.9115 13.6558 12.3699C13.1805 12.8284 12.8724 13.2085 12.7274 13.5143C12.584 13.8176 12.5127 14.1502 12.5127 14.5126Z"
        fill="url(#paint0_linear_4183_66103)"
        stroke="url(#paint1_linear_4183_66103)"
        strokeWidth="0.866667"
      />
      <path
        d="M12.8354 16.4419C12.6065 16.2119 12.3277 16.0962 11.9999 16.0962C11.6696 16.0962 11.3918 16.2124 11.1594 16.4419C10.9276 16.6734 10.8119 16.9524 10.8119 17.28C10.8119 17.6075 10.9281 17.8865 11.1594 18.118C11.3912 18.348 11.6696 18.4637 11.9999 18.4637C12.3282 18.4637 12.6065 18.3475 12.8354 18.118C13.0647 17.8865 13.1778 17.6075 13.1778 17.28C13.1778 16.9524 13.0647 16.6734 12.8354 16.4419Z"
        fill="url(#paint2_linear_4183_66103)"
      />
      <path
        d="M12.311 0.913348C10.1896 0.910052 8.10489 1.4666 6.26707 2.52685C4.42921 3.58712 2.90326 5.11362 1.84312 6.95237C0.782982 8.79112 0.226147 10.8771 0.228772 12.9999C0.231381 15.1097 0.786501 17.1817 1.83849 19.0096L0.289392 23.6551L0.289387 23.6551C0.225582 23.8466 0.216322 24.052 0.262646 24.2484C0.30897 24.4449 0.409049 24.6245 0.551672 24.7672C0.694298 24.91 0.873831 25.0101 1.07015 25.0565C1.26648 25.1028 1.47183 25.0936 1.66318 25.0297L1.6632 25.0297L6.30552 23.4796C7.90126 24.3972 9.68563 24.9379 11.5224 25.06C13.3722 25.1831 15.2254 24.8784 16.9386 24.1696C18.6518 23.4608 20.179 22.3669 21.4018 20.9726C22.6246 19.5783 23.5103 17.921 23.9903 16.1292C24.4702 14.3374 24.5316 12.459 24.1695 10.6396C23.8075 8.82026 23.0318 7.10868 21.9026 5.63748C20.7733 4.16628 19.3208 2.97493 17.6575 2.15571C15.9942 1.33651 14.1648 0.911414 12.311 0.913348ZM12.311 0.913348C12.311 0.913348 12.3109 0.913348 12.3109 0.913348L12.311 1.00001L12.3111 0.913348C12.3111 0.913348 12.311 0.913348 12.311 0.913348ZM7.02363 21.3719L7.02348 21.3718C6.84966 21.2617 6.64817 21.2033 6.44245 21.2033H6.44235C6.3259 21.2035 6.21028 21.2228 6.10006 21.2604C6.09994 21.2604 6.09981 21.2604 6.09969 21.2605L3.03439 22.2829L4.05636 19.2149C4.05637 19.2149 4.05637 19.2149 4.05638 19.2149C4.1073 19.0623 4.12373 18.9003 4.10449 18.7406C4.08524 18.5809 4.03079 18.4274 3.94506 18.2913L3.94503 18.2913C2.77185 16.4313 2.25013 14.2337 2.462 12.0444C2.67387 9.85519 3.6073 7.79862 5.11533 6.19848C6.62336 4.59834 8.62035 3.54551 10.7919 3.20571C12.9635 2.86591 15.1863 3.25843 17.1105 4.3215C19.0347 5.38457 20.551 7.05781 21.4206 9.07783C22.2902 11.0978 22.4638 13.3499 21.9141 15.4795C21.3643 17.6091 20.1224 19.4952 18.3839 20.841C16.6454 22.1868 14.509 22.9159 12.3111 22.9133H12.311C10.4381 22.9121 8.60417 22.3775 7.02363 21.3719ZM12.311 23C10.4216 22.9988 8.57154 22.4594 6.97711 21.445L12.311 23Z"
        fill="url(#paint3_linear_4183_66103)"
        stroke="url(#paint4_linear_4183_66103)"
        strokeWidth="0.173333"
      />
      <defs>
        <linearGradient id="paint0_linear_4183_66103" x1="9.76343" y1="9.8583" x2="15.4025" y2="11.505" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint1_linear_4183_66103" x1="9.76343" y1="9.8583" x2="15.4025" y2="11.505" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint2_linear_4183_66103" x1="10.8119" y1="16.8191" x2="13.1783" y2="17.83" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint3_linear_4183_66103" x1="0.31543" y1="8.32824" x2="24.3154" y2="18.5878" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
        <linearGradient id="paint4_linear_4183_66103" x1="0.31543" y1="8.32824" x2="24.3154" y2="18.5878" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0A2E5A" />
          <stop offset="0.156625" stopColor="#3785A1" />
          <stop offset="0.527426" stopColor="#C6A97D" />
          <stop offset="1" stopColor="#D67252" />
        </linearGradient>
      </defs>
    </svg>;
  }

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1973 14.5126C13.1994 14.8312 13.0006 15.1201 12.682 15.1201H12.3072C12.0743 15.1201 11.8626 14.9687 11.781 14.7506C11.6997 14.5383 11.6603 14.3538 11.6603 14.1931C11.6603 13.8898 11.7022 13.5987 11.786 13.3182C11.8699 13.0366 11.9931 12.7758 12.1578 12.5317C12.3204 12.2901 12.6432 11.9004 13.1241 11.3626C13.6069 10.8278 13.8489 10.386 13.8489 10.0388C13.8489 9.36352 13.41 9.02537 12.5306 9.02537C12.3501 9.02537 12.1667 9.06287 11.98 9.13791C11.5317 9.31816 10.9691 9.24719 10.7417 8.82082C10.572 8.50262 10.6528 8.09959 10.9761 7.93993C11.5088 7.67692 12.1469 7.54541 12.8922 7.54541C13.6686 7.54541 14.3186 7.76225 14.8414 8.19593C15.3607 8.63011 15.6223 9.20683 15.6223 9.92457C15.6223 10.4174 15.5284 10.8344 15.3385 11.172C15.149 11.5117 14.8167 11.9115 14.3404 12.3699C13.8651 12.8284 13.5569 13.2085 13.412 13.5143C13.2685 13.8176 13.1973 14.1502 13.1973 14.5126Z"
        fill="#C6A97D"
        stroke="#C6A97D"
        strokeWidth="0.866667"
      />
      <path
        d="M13.5199 16.4419C13.2911 16.2119 13.0123 16.0962 12.6845 16.0962C12.3541 16.0962 12.0763 16.2124 11.844 16.4419C11.6121 16.6734 11.4965 16.9524 11.4965 17.28C11.4965 17.6075 11.6126 17.8865 11.844 18.118C12.0758 18.348 12.3541 18.4637 12.6845 18.4637C13.0128 18.4637 13.2911 18.3475 13.5199 18.118C13.7492 17.8865 13.8624 17.6075 13.8624 17.28C13.8624 16.9524 13.7492 16.6734 13.5199 16.4419Z"
        fill="#C6A97D"
        stroke="#C6A97D"
        strokeWidth="0.866667"
      />
      <path
        d="M12.9956 0.913348C10.8742 0.910052 8.78946 1.4666 6.95164 2.52685C5.11378 3.58712 3.58783 5.11362 2.52769 6.95237C1.46755 8.79112 0.910717 10.8771 0.913343 12.9999C0.915952 15.1097 1.47107 17.1817 2.52306 19.0096L0.973963 23.6551L0.973958 23.6551C0.910152 23.8466 0.900893 24.052 0.947216 24.2484C0.993541 24.4449 1.09362 24.6245 1.23624 24.7672C1.37887 24.91 1.5584 25.0101 1.75472 25.0565C1.95105 25.1028 2.1564 25.0936 2.34775 25.0297L2.34777 25.0297L6.99009 23.4796C8.58583 24.3972 10.3702 24.9379 12.207 25.06C14.0567 25.1831 15.91 24.8784 17.6232 24.1696C19.3364 23.4608 20.8635 22.3669 22.0864 20.9726C23.3092 19.5783 24.1949 17.921 24.6749 16.1292C25.1548 14.3374 25.2161 12.459 24.8541 10.6396C24.4921 8.82026 23.7164 7.10868 22.5871 5.63748C21.4579 4.16628 20.0053 2.97493 18.342 2.15571C16.6788 1.33651 14.8494 0.911414 12.9956 0.913348ZM12.9956 0.913348C12.9955 0.913348 12.9955 0.913348 12.9955 0.913348L12.9956 1.00001L12.9957 0.913348C12.9956 0.913348 12.9956 0.913348 12.9956 0.913348ZM7.7082 21.3719L7.70805 21.3718C7.53423 21.2617 7.33274 21.2033 7.12702 21.2033H7.12692C7.01047 21.2035 6.89485 21.2228 6.78463 21.2604C6.78451 21.2604 6.78438 21.2604 6.78426 21.2605L3.71896 22.2829L4.74093 19.2149C4.74094 19.2149 4.74095 19.2149 4.74095 19.2149C4.79187 19.0623 4.8083 18.9003 4.78906 18.7406C4.76981 18.5809 4.71536 18.4274 4.62963 18.2913L4.6296 18.2913C3.45642 16.4313 2.9347 14.2337 3.14657 12.0444C3.35844 9.85519 4.29187 7.79862 5.7999 6.19848C7.30793 4.59834 9.30492 3.54551 11.4765 3.20571C13.6481 2.86591 15.8709 3.25843 17.7951 4.3215C19.7193 5.38457 21.2355 7.05781 22.1052 9.07783C22.9748 11.0978 23.1484 13.3499 22.5986 15.4795C22.0489 17.6091 20.807 19.4952 19.0685 20.841C17.33 22.1868 15.1936 22.9159 12.9956 22.9133H12.9956C11.1227 22.9121 9.28874 22.3775 7.7082 21.3719ZM12.9956 23C11.1061 22.9988 9.25611 22.4594 7.66168 21.445L12.9956 23Z"
        fill="#737373"
        stroke="#737373"
        strokeWidth="0.173333"
      />
    </svg>
  );
};

export default SpeechQuestionIcon;
